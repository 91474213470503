const accordions = document.querySelectorAll("[data-ctrl='accordion']");

if (accordions) {
  accordions.forEach((accordion) => {
    const buttons = accordion.querySelectorAll(".c-accordion__button");
    buttons.forEach((button) => {
      button.addEventListener("click", function () {
        const isExpanded = this.getAttribute("aria-expanded") === "true";
        this.setAttribute("aria-expanded", (!isExpanded).toString());
      });
    });
  });
}
